window.NREUM || (window.NREUM = {});

NREUM.init = {
  distributed_tracing: { enabled: true },
  privacy: { cookies_enabled: true },
  ajax: { deny_list: ["bam.nr-data.net"] }
};

NREUM.loader_config = {
  accountID: "3816892",
  trustKey: "3045570",
  agentID: "594437553",
  licenseKey: "NRJS-94f93f69b80cd491d10",
  applicationID: "594437553"
};
NREUM.info = {
  beacon: "bam.nr-data.net",
  errorBeacon: "bam.nr-data.net",
  licenseKey: "NRJS-94f93f69b80cd491d10",
  applicationID: "594437553",
  sa: 1
}; /*! For license information please see nr-loader-spa-1.240.0.min.js.LICENSE.txt */

if (window.startWebTracking) {
  window.startWebTracking();
}
